import { axiosInstance, axiosWithoutLoading } from '@/libs/axios';
import router from '@/router';
import store from '@/store';
import { isTakeAway, isPrePayment } from '@/services/RouteMiddlewares';
const ENUM_STATUS = {
    PENDING: 1,
    PAID: 2
};
const ENUM_NO_REDIRECT_PAGES = ['status-payment-iban'];

const ROUTE_PAYMENT_DEFAULT = 'payment-status';

export default class Payments {
    constructor() {
        this.router = router;
        this.store = store;
    }

    /**
     * identifica se existe pagamento aberto no backend
     * se existir move o usuário para pagina referente
     */
    async checkPaymentOpened() {        

        let paymentOpened = await this.store.dispatch('Payment/checkPaymentOpened');

        if (paymentOpened && Object.keys(paymentOpened).length) {

            const currentPage = this.router.currentRoute?.name;            
            if (ENUM_NO_REDIRECT_PAGES.includes(currentPage)) return;

            if (parseInt(paymentOpened.status_payment.id) == ENUM_STATUS.PENDING) {
                await this.store.dispatch('Payment/startCountdownPayment');
                this.router.push({
                    name: ROUTE_PAYMENT_DEFAULT
                });
            }

            if (parseInt(paymentOpened.status_payment.id) == ENUM_STATUS.PAID && paymentOpened.is_view_order == 0) {
                this.router.push({
                    name: ROUTE_PAYMENT_DEFAULT
                });
            }
        }
    }

    /**
     * bloquea e desbloquea mesa quando esta no apgamento
     * @param {boolean} block 
     */
    static async blockUnblockTablePayment(block) {
        let flagPaymentFirst = await isTakeAway() || await isPrePayment() || false;
        if (flagPaymentFirst) { return; } //se for takeaway não bloqueia a mesa
        axiosWithoutLoading({
            method: 'POST',
            url: 'table/block-unblock',
            data: {
                block
            }
        }).catch(async (error) => { });
    }

    /**
     * bloquea e desbloquea mesa quando esta no apgamento
     * @param { integer } ordernum
     */
    static async expiresOrderStatus(order) {
        let flagTakeAway = await isTakeAway() || false;
        if (flagTakeAway) { return; }
        axiosWithoutLoading({
            method: 'POST',
            url: 'payments/expires-order',
            data: {
                id: order
            }
        })
    }
}