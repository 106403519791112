import {
    axiosInstance, axiosWithoutLoading
} from "@/libs/axios";

import SiteInit from "@/services/SiteInit";
import store from "@/store";
import i18n from "@/i18n";
import { io } from "socket.io-client";



export default {
    loading(context, value) {
        context.commit('setLoading', value)
    },
    setLocale(context, payload) {
        payload.i18n.locale = payload.newLang;
        context.commit('setLocale', [payload.lang, payload.newLang])
    },
    setGeolocation(context, value) {
        context.commit('setGeolocation', value)
    },
    setGeolocationNeed(context, value) {
        context.commit('setGeolocationNeed', value)
    },

    onValidationUrlStore(context, data){                
        let onValidTableNumber = false;
        let onValidStoreNuber = false;
        if ('tableNumber' in data && data.tableNumber.length){                                    
            let regexDigitInteger = /^(0|[0-9A-Za-z]*)$/;
            let resultDigit = regexDigitInteger.test(data.tableNumber);            
            onValidTableNumber = resultDigit;
        }
        if ('storeId' in data){                                    
            let regexDigitInteger = /^(0|[1-9][0-9]*)$/;
            let resultDigit = regexDigitInteger.test(data.storeId);            
            onValidStoreNuber = resultDigit;
        }
        return onValidStoreNuber && onValidTableNumber;
    },

    /**
     * adiciona os dados do serial,terminal e mesa
     * @param {*} context 
     * @param Object {serial, terminal, table} 
     */
    setSerialStore(context, value) {
        context.commit("setSerialStore", value);
    },
    /**
     * Carrega as configurações da loja /api
     * @param {*} context      
     */
    async loadStoreConfig(context) {
        let unitId = context.getters.storeSerial?.storeId;
        
        const url = unitId ? `store/get-configs/${unitId}` : 'store/get-configs';

        return await axiosInstance({
            method: 'GET',
            url: url,
        }).then(response => response.data.data)
            .then(data => {
                context.commit('setGeolocation', {});
                context.commit('setStoreConfig', data);
                window.document.title = data?.titulo ?? 'Biip'

                //depois que carrega os dados principais inicia
                //os items da app
                SiteInit.start();

                return data;
            }).catch(error => {                
                let errorObj = {
                    type: "error",
                    message: i18n.t('unvailable.system_down_msg'),
                }
                store.dispatch('Modal/setAlert', errorObj, {
                    root: true
                });

            });
    },
    setSelectLangNeed(context, value) {
        context.commit('setSelectLangNeed', value);
    },
    toggleFormFixed(context) {
        if (context.getters.isDesktop) {
            context.commit('setActiveFormFloating', !context.getters.activeFormFloating);
            if (context.getters.activeFormFloating) {
                document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
                document.getElementsByClassName('backdrop_bar')[0].classList.remove('d-none');
            } else {
                document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
                document.getElementsByClassName('backdrop_bar')[0].classList.add('d-none');
            }
        } else {
            if (context.getters.activeMenuFooter) {
                context.commit('setActiveMenuFooter', false);
            } else {
                context.commit('setActiveMenuFooter', true);
            }
        }
    },
    toggleBackdrop(context, value) {
        if (value) {
            document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
            document.getElementsByClassName('backdrop_bar')[0].classList.remove('d-none');
        } else {
            document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
            document.getElementsByClassName('backdrop_bar')[0].classList.add('d-none');
        }
    },
    closeMenuByElement(context, value) {
        let hasClassinElement = value.event.target.classList.contains('content-menu--default');
        if (!hasClassinElement) return;
        context.dispatch(value.module, value.variables ? value.variables : false, { root: true });
    },
    async checkHasLangInLocales(context, lang) {
        const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
        let langsLocales = [];
        locales.keys().map(item => {
            let element = item.match(/([A-Za-z0-9-_]+)\./i)[1];
            langsLocales.push(element);
        });
        lang = langsLocales.find(item => item === lang);
        return lang ?? 'gb';
    },
    async callEmployee(context) {
        // context.commit('setLoading', true);
        let fetchTblNumber = context.getters.storeSerial?.tableNumber;

        if (!fetchTblNumber) return;

        return await axiosWithoutLoading({
            method: 'GET',
            url: `store/call-employee/${fetchTblNumber}`,
        }).then(response => response.data.data)
            .then(data => {
                context.commit('setLoading', false);
                return data;

                // context.commit('setProduct', data);
                // context.commit('setLoading', false);
                // //adiciona o item no carrinho tb
                // context.dispatch('Cart/resetAddProdut', data, {root: true})
                // return data;
            })
    },
    async sessionStoreOpen(context, loading) {     
        const typeAxios = loading ? axiosInstance : axiosWithoutLoading; 
        return await typeAxios({
            method: 'GET',
            url: `store/session-status`,
        }).then(response => response.data.data)
            .then(data => {                
                context.commit('setLoading', false);
                let hasOpenedSession = data?.value && 4 === data.value;                
                
                if (false === hasOpenedSession){

                    let isPrepaymentContext = true == context.getters.isPrePayment;
                    let contextMessage = isPrepaymentContext ? i18n.t('errors.noOpenedSession') : i18n.t('zserros.session_closed');
                    let msgAlert = {'message': contextMessage}

                    store.dispatch("Modal/setAlert", msgAlert, {
                        root: true
                    });
                }

                return hasOpenedSession;
            })
    },    
};