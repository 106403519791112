import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    loading: false,
    /**
     * objeto com estrutura da transacao em aberto
     * @var {Object}
     */
    transaction: {}, 
    /**
     * valor de gorjeta
     * @var {float}
     */
    gratification: 0,   
    invoicing: {
      mobile_ddi: "351"
    },
    totalToPay: 0,
    /**
     * identifica se o modal de split ativo ou nao
     * @var {boolean}
     */
    openSplitAccount: false,
    /**
     * objeto contem os dados de split como valores
     * e outros temas
     */
    splitData: {},
    isActiveCommentRate: false,
    /**
     * Js Interval para a consulta de status de pagamentos
     */
    clockInterval: null,
    /**
     * JS Timeout delimitando um tempo para que o processo 
     * de pagamento seja concluido pelo cliente.     
     */
    clockToConcludedPayment: null,
    /**
     *  JS Interval que mantem requisicoes de bloqueios à mesa
     *  durante o procedimento de pagamento     
     */
    clockTableBlockedInterval: null,

    /**
     * GOOGLE PAY
     * Dados de pedidos e de configuracoes
     */
    googlePayOrder: null,
    googlePayData: null,
    googleBtnEnable:false,

    /**
     * APPLE PAY
     * Dados de pedidos e de configuracoes
     */
    applePayOrder: null,
    applePayData: null,
    appleBtnEnable:false,
    /**
     * 
     * Identifica se o usuário escolheu 
     * a opção de fatura com NIF
     * na página de pagamento, 
     * caso true habilita os campos do formulário
     */
    invoicingWithNif: {
      checked: false,
      emailRequired: false,
    }
  },
  actions,
  getters,
  mutations,
};