import { randomNumber } from "@/utils";

export default {
    setAlert(context, value){
        let props = {
            key: randomNumber(),
            type: 'warning',
            isVisible: true,
        }
        props = Object.assign(props, value);
        context.commit('setAlert', props);
    }
};