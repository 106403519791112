export default {
  setLoading(state, value) {
    state.loading = value;
  },
  setSocket(state, value){
    state.socket = value;
  },
  setLocale(state, [value, newValue]){
    state.locale = newValue;
    sessionStorage.setItem("x-biip-locale", value);
  },
  setStoreConfig(state, value){
    state.storeConfig = value;
  },
  setGeolocation(state, value){
    state.geolocation = value;
  },
  setGeolocationNeed(state, value){
    state.geolocationNeed = value;
  },
  setSelectLangNeed(state, value){
    state.selectLangNeed = value;
  },
  setSerialStore(state, value){
    state.storeSerial = value;
    sessionStorage.setItem('x-biip-storeSerial', JSON.stringify(value));
  },
  setMenuOpen(state, value) {
    state.menuOpen = value;
  },
  setActiveMenuFooter(state, value) {
    state.activeMenuFooter = value;
  },
  setActiveFormFloating(state, value) {
    state.activeFormFloating = value;
  },
  setIsDesktop(state, value) {
    state.isDesktop = value;
  }, 
};