import { axiosInstance, axiosWithoutLoading } from "@/libs/axios";

export default { 
    setLoading(context, value){
        context.commit('setLoading', value);
    },
    async loadMenu(context){
        context.commit('setLoading', true);

        return await axiosInstance({
            method: 'GET',
            url: 'menu',            
        }).then(response => response.data.data)
        .then(data => {            
            context.commit('setMenuItems', data);
            context.commit('setLoading', false);
            return data;
        })
    },
    async loadMenuImages(context){        
        context.commit('setLoading', true);

        return await axiosWithoutLoading({
            method: 'GET',
            url: 'menu',  
            params: {
                includeImages: 1
            }          
        }).then(response => response.data.data)
        .then(data => {            
            context.commit('setMenuItems', data);
            
            return data;
        })
    },
    async loadMenuProductDetails(context, payload){
        context.commit('setLoading', true);

        const productId = payload.productId;
        const familyId = payload.familyId;
        const prodRef = payload.product;                        
        
        return await axiosWithoutLoading({
            method: 'GET',
            url: `menu/product/${productId}/${familyId}`,            
        })
        .then(response => response.data.data)
        .then(data => {

            context.commit('setProduct', data);
            context.commit('setLoading', false);

            //adiciona o item no carrinho tb
            context.dispatch('Cart/resetAddProdut', data, {root: true})
            return data;
        })
    },  
    /**
     * método generico para alterar o state de 
     * variaveis que é para ativar ou desativar
     * a visao de modals
     */
    setToogleActive(context, {varName, varValue}){        
        context.dispatch('Site/toggleBackdrop', varValue, {root: true});
        context.commit('setToogleActive', {varName, varValue})
    },
    setMenuBreadcrumb(context, value){                
        context.commit('setMenuBreadcrumb', value)
    },
};