import _ from "lodash";

export default {
    isLoading: state => state.loading,
    locale: state => (state.locale || sessionStorage.getItem('x-biip-locale')) || 'pt',
    storeSerial: state => _.cloneDeep(state.storeSerial || (JSON.parse(sessionStorage.getItem('x-biip-storeSerial')))),    
    storeConfig: state => _.cloneDeep(state.storeConfig), 
    geolocation: state => _.cloneDeep(state.geolocation),    
    geolocationNeed: state => state.geolocationNeed,
    selectLangNeed: state => state.selectLangNeed,
    menuOpen: state => state.menuOpen,    
    activeMenuFooter(state) {
        return state.activeMenuFooter;
    },
    activeFormFloating(state) {
        return state.activeFormFloating;
    },
    isDesktop(state) {
        return state.isDesktop;
    },    
};