import { checkStoreConfigs } from '@/services/RouteMiddlewares';
import Vue from 'vue'
import VueRouter from 'vue-router'
import menuProducts from './menuProducts';
import payment from "./payment";
import opinion from "./opinion";
import table from "./table";
import takeaway from "./takeaway";

Vue.use(VueRouter)

const baseRoutes = [
  {
    path: '/',
    name: 'language',
    component: () => import(/* webpackChunkName: "language" */ '@/views/Language')
  },   
  {
    path: '/404',
    name: 'Page404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue')
  },
  {
    path: '/serial-notfound',
    name: 'PageNotSerial',
    component: () => import(/* webpackChunkName: "404" */ '@/views/PageNotSerial.vue')
  },
  {
    path: '/page-offline',
    name: 'PageOffline',
    component: () => import(/* webpackChunkName: "404" */ '@/views/PageOffline.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: () => import(/* webpackChunkName: "Terms" */ '@/views/Terms.vue')
  },
  {
    path: '/iframe',
    name: 'iframe-default',
    component: () => import( /* webpackChunkName: "iframe" */ '@/views/Payment/Iframe'),
  },
  {
    path: '/status-payment-iban',
    name: 'status-payment-iban',
    component: () => import( /* webpackChunkName: "payment-kevin" */ '@/views/Payment/Kevin/index'),
  },
  {
    path: '/status-payment-cards',
    name: 'status-payment-cards',
    component: () => import( /* webpackChunkName: "payment-kevin" */ '@/views/Payment/MastercardOrVisa/CardsStatusPage/index'),
  },  
  {
    path: '*',
    redirect: '/404'
  } 
]

const routes = baseRoutes.concat(menuProducts).concat(payment).concat(opinion).concat(table).concat(takeaway);
const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next) => { 
  checkStoreConfigs(to, from, next)  
});

export default router
