import _ from "lodash"

export default {    
    loading: state => state.loading,    
    invoicing: state => _.cloneDeep(state.invoicing),
    totalToPay: state => state.totalToPay,
    gratification: state => state.gratification,
    openSplitAccount: state => state.openSplitAccount,
    splitData: state => _.cloneDeep(state.splitData),
    transaction: state => _.cloneDeep(state.transaction || JSON.parse(sessionStorage.getItem('x-transaction'))),
    isActiveCommentRate: state => state.isActiveCommentRate,
    googlePayOrder: state => state.googlePayOrder,
    googlePayData: state => state.googlePayData,
    googleBtnEnable: state => state.googleBtnEnable,
    applePayOrder: state => state.applePayOrder,
    applePayData: state => state.applePayData,
    appleBtnEnable: state => state.appleBtnEnable,
    invoicingWithNif: state => state.invoicingWithNif,
}