import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    /**
     * ativa o modal de comentarios de um produto
     * e adicionar observacao sobre o mesmo
     * @var {Boolean}
     */
    isActiveComment: false,
    isActiveBtnViewCart: false,
    isActiveViewCart: false,
    isActiveSuggestions: false,
    /**
     * identifica se o botão que vai adicionar o item
     * está ativo ou não, existe momentos que precisa     
     * desabilitar o botão, eg: quando o item esta com quantia 0
     * @var {Boolean}
     */
     isActiveBtnAddItemCart: true,
    /**
     * objeto que armazena os dados do item
     * que será adicionado no carrinho
     * esse objeto salva todos os itens que foi selecionado
     * pelo usuario
     * @var {Object}
     */
    productToAdd: {},
    /**
     * Objeto que construi as dependencias e triggers
     * de exibir ou não os subniveis, quando o user
     * clica em uma opção é verificado se o mesmo possui     
     */
    productTrigger: {},
    /**
     * Objeto que contém dados do carrinho
     * @var {Object}
     */
    cart: {},
    /**
     * Objeto que contém itens de sugestão que o usuário selecionou
     * para adicionar no carrinho
     */
     productSuggestions: {}
  },
  actions,
  getters,
  mutations,
};