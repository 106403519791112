import _ from "lodash";

export default {    
    isActiveComment: state => state.isActiveComment,
    isActiveViewCart: state => state.isActiveViewCart,
    isActiveBtnViewCart: state => state.isActiveBtnViewCart,
    isActiveSuggestions: state => state.isActiveSuggestions,
    productToAdd: state => _.cloneDeep(state.productToAdd),
    productTrigger: state => _.cloneDeep(state.productTrigger),
    productSuggestions: state => _.cloneDeep(state.productSuggestions), 
    cart: state => _.cloneDeep(state.cart),
    isActiveBtnAddItemCart: state => state.isActiveBtnAddItemCart,
};