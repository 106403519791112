export default {
  setIsActiveFreebee(state, value) {
    state.isActiveFreebee = value;
  },
  setIsActiveFreebeePromotion(state, value) {
    state.isActiveFreebeePromotion = value;
  },
  setFreebeeItems(state, value){
    state.freebeeItems = value
  },
  setMobile(state, value){
    state.mobile = value
  },
  setCampaigns(state, value){
    state.campaigns = value
  }
};