import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    loading: false,
    isTakeAway: false,
    isTakeAwayNow: false,
    openModalMorada: false,
    openModalHour: false,
    takeAwayDateTime: null,
    takeAwaySchedule: null,
    takeAwayComment: "",
    takeAwayOrderNumber: null,
    takeAwayStatus: null,
    takeAwayBiipProcess: null,
  },
  actions,
  getters,
  mutations,
};