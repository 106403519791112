import {
    fetchTableItems
} from '@/services/RouteMiddlewares';

const routes = [{
        path: '/table',
        name: 'table-route',
        component: () => import( /* webpackChunkName: "table" */ '@/views/Table'),
        children: [{
            path: '/',
            name: 'table',  
            beforeEnter: async (to, from, next) => {
                await fetchTableItems(to, from, next)
            },        
            component: () => import( /* webpackChunkName: "table" */ '@/views/Table/TableItems')
        },
        // {
        //     path: 'product/:productId?/:familyId?/',
        //     name: 'menu-product',
        //     component: () => import( /* webpackChunkName: "table" */ '@/views/Menu/MenuProduct'),
        //     // children: children, 
        // }
    ]
    },    
]

export default routes;