export default {
  setTableIsEmpty(state, value) {
    state.tableIsEmpty = value;
  },
  setLoading(state, value){
    state.loading = value;
  },
  setTableData(state, value){
    state.tableData = value;
  }
};