import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    loading: false,
    /**
     * menu/ementa de itens da loja
     * @var object
     */
    menuItemKey: (new Date).getTime(),
    menuItems : {},
    /**
     * objeto que contém os detalhes de um 
     * produto que está sendo visto
     * @var object
     */
    product: {},
    isActiveNutritional: false,
    breadcrumb: {},   
  },
  actions,
  getters,
  mutations,
};