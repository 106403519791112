const routes = [
    {
        path: '/opinion',
        name: 'opinion',   
        component: () => import(/* webpackChunkName: "opinion" */ '@/views/Opinion'),
        // children: children, 
    },
    {
        path: '/opinion-sent',
        name: 'opinion-sent',   
        component: () => import(/* webpackChunkName: "opinion" */ '@/views/Opinion/OpinionSent.vue'),
        // children: children, 
    },
]

export default routes;
