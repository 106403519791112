import { axiosInstance, axiosWithoutLoading } from "@/libs/axios";
import router from '@/router'
import Payments from '@/services/Payments';
import { isTakeAway } from '@/services/RouteMiddlewares';
import store from '@/store';


export default {
    setInvoicing(context, value) {
        context.commit('setInvoicing', value);
    },
    clearInvoicing(context) {
        context.commit('clearInvoicing');
    },
    clearPaymentInputs(context) {
        context.commit('clearInvoicing');
        context.commit('setGratification', 0);
    },
    setTotalToPay(context, value) {
        // arredonda possiveis dizimas
        let roundValue = parseFloat(value).toFixed(2);
        // toFixed devolve uma string. E necesario converter para float
        roundValue = parseFloat(roundValue);
        context.commit('setTotalToPay', roundValue);
    },
    setIsActiveCommentRate(context, value) {
        context.commit('setIsActiveCommentRate', value)
    },
    setOpenSplitAccount(context, value) {
        context.dispatch('Site/toggleBackdrop', value, {
            root: true
        });
        context.commit('setOpenSplitAccount', value)
    },
    setSplitData(context, value) {
        context.commit('setSplitData', value);
    },
    setTransaction(context, value) {        
        context.commit('setTransaction', value)
    },
    setGratification(context, value) {
        context.commit('setGratification', value);
    },
    /**
     * inicia o contador para verificar se o pagamento foi 
     * ou nao feito no lado do backend
     * precisamos garantir que 
     * o usuario tenha uma atualização
     */
    startCountdownPayment(context) {
        /** TIMMERS FOR FETCH STATUS PAYMENT */
        const THIRTY_SECONDS = 30000; //MILESEGUNDOS
        const FIVE_SECONDS = 5000; //MILESEGUNDOS
        let _default_timer = THIRTY_SECONDS;
        
        const trxtion = context.getters?.transaction.id;        
        const activeTerminal = store.getters['Site/storeSerial'].terminal ?? null;

        /** WHITELIST FOR FAST CHECK PAYMENTS */
        const serialsFastCheckList = ['D_9VB1M4NP', 'S5188NIG59'];
        if (serialsFastCheckList.includes(activeTerminal)) _default_timer = FIVE_SECONDS;

        let interval = setTimeout(async () => {
            /** renova o bloqueio da mesa */

            await Payments.blockUnblockTablePayment(true);
           
        }, _default_timer);

        context.commit('setClockInterval', interval);
    },

    /**
     * O utlizador tem um timming paga concluir o pagamento no biip.
     * O timmer pode ser renovado com a interacao do utilizador na tela.
     */
    async startTimmerToConcludePayment(context, milliseconds) {

        /** reseta timmer anterior */
        context.commit('clearClockToConcludedPayment');

        /** inicia o procedimento de bloqueio de mesa */
        context.dispatch('setTableBlockedInterval', 30000);

        let expiresTime = setTimeout(async () => {

            /* desativa o modal freebee, se aberto */
            context.dispatch('Freebee/setIsActiveFreebee', false, {
                root: true
            });

            /* remove o timmer de verificacao de status de pagmento */
            context.commit('clearClockInterval');

            /* remove o timmer de inatividade no ecra */
            context.commit('clearClockToConcludedPayment');

            /* remove o timmer de bloqueios sequenciais */
            context.commit('clearTableBlockedInterval');

            /** liberta a mesa */
            await Payments.blockUnblockTablePayment(false);

            /** expira a transacao no backoffice */
            let order = context.getters?.transaction.id;
            await Payments.expiresOrderStatus(order);

            /** redirecina para o menu */
            router.push({ name: 'menu' });
        }, milliseconds);

        context.commit('setClockToConcludedPayment', expiresTime);
    },

    /**
     * verifica se existe pagamentos em aberto para a mesa
     * @param {*} context 
     * @returns 
     */
    async checkPaymentOpened(context) {
        return await axiosInstance({
            method: 'GET',
            url: 'payments/check-opened'
        }).then(response => response.data.data)
            .then(data => {
                // context.commit("setTransaction", data);
                return data;
            })
    },

    /**
     * @method    onFetchTransaction
     * metodo deve ser acionado quando houver um trigger
     * vindo do Firebase RTDB para a consulta no Backoffice 
     */
    async onFetchTransaction(context) {

        /** consulta status da transacao */            
        let res = await context.dispatch('getTransaction', { id: context.getters.transaction.id })            

        //se chegou um response com orderId, muito provavelmente o pagamento foi um sucesso, entao setamos na storage para pegar no state do takeAway status
        if (res?.takeaway?.order_number) {
            await store.dispatch("TakeAway/setTakeAwayOrderNumber", res.takeaway.order_number, {
                root: true
            });
            
            sessionStorage.setItem('takeAwayOrderNumber', res.takeaway.order_number);                              
        }

        if (res?.process) {
            await store.dispatch("TakeAway/setTakeAwayBiipProcess", res.process, {
                root: true
            });
            sessionStorage.setItem('takeAwayBiipProcess', res.process);                
        } 

        if (res?.takeaway?.order_pickup) {
            await store.dispatch("TakeAway/setTakeAwayPickupTime", res.takeaway.order_pickup, {
                root: true
            });
            sessionStorage.setItem('takeAwayPickupTime', res.takeaway.order_pickup);                
        } 
    },

    async getTransaction(context, params) {        
        if ('clearInterval' in params) {
            /* reset de todos os timmers da applicacao */
            context.dispatch('resetAllTimmers');

            /* desbloquiea a mesa  */

            await Payments.blockUnblockTablePayment(false);
        }

        /** recupera a trasacao do localstorag */        
        if (!('id' in params) || !params?.id){   
            let fromStorage = JSON.parse(sessionStorage.getItem('x-transaction'));
            params.id = fromStorage?.id;
        }
        
        return await axiosWithoutLoading({
            method: 'GET',
            url: 'payments',
            params
        }).then(response => response.data.data)
            .then(async data => {
                if (data.status_payment?.slug != 'pending') {
                    context.dispatch('resetAllTimmers');

                    await Payments.blockUnblockTablePayment(false);
                }

                let enabledTakeAway = await isTakeAway();                
                if (true === enabledTakeAway){
                    if (data?.takeaway?.order_number) {
                        await store.dispatch("TakeAway/setTakeAwayOrderNumber", data.takeaway.order_number, {
                            root: true
                        });
                        sessionStorage.setItem('takeAwayOrderNumber', data.takeaway.order_number);                              
                    }
        
                    if (data?.takeaway?.order_pickup) {
                        await store.dispatch("TakeAway/setTakeAwayPickupTime", data.takeaway.order_pickup, {
                            root: true
                        });
                        sessionStorage.setItem('takeAwayPickupTime', data.takeaway.order_pickup);                
                    } 
                    
                    if (data?.takeaway?.order_comment) {
                        await store.dispatch("TakeAway/setTakeAwayComment", data.takeaway.order_comment, {
                            root: true
                        });                                      
                    }                    
                }

                if (true === data?.pooling) {
                    context.dispatch('startCountdownPayment');
                }
                
                context.commit("setTransaction", data);
                return data;
            }).catch(async (error) => {
                context.dispatch('resetAllTimmers');

                /* desbloquiea a mesa  */
                await Payments.blockUnblockTablePayment(false);

                /** redireciona o utilizador*/
                router.push({ name: 'menu' });
            });
    },

    /**
     * Em algumas telas, a interação do utlizador renova 
     * o timmer de inatividade.
     * @param {*} context 
     * @param {*} value 
     */
    async changePaymentData(context, value = 90000) {

        await context.dispatch('startTimmerToConcludePayment', value, {
            root: false
        });
    },

    /**
     * Inicia um timmer que manterá a mesa bloqueada.
     * Para isso, são enviadas requisições para o Mobile Interface
     * Manter as as 'Blocking Requests' se faz necessario, pois 
     * não e respeitado o seu status anterior. Isso signigica que 
     * o Mobile Interface sobrescreve o bloqueio corrente quando
     * novas requisicoes de bloqueio sao enviadas.
     * @method setTableBlockedInterval
     * @param {*} context 
     */
    setTableBlockedInterval(context, milliseconds) {

        /* remove o timmer de bloqueios sequenciais anterior*/
        context.commit('clearTableBlockedInterval');

        let keepTbBlocked = setInterval(async () => {
            await Payments.blockUnblockTablePayment(true).catch(error => {
                context.commit('clearTableBlockedInterval');
            });
        }, milliseconds);

        context.commit('setTableBlockedInterval', keepTbBlocked);
    },

    /**
     * Esta actions deve ser chamada quando for 
     * necessiario encerrar todos os "Clocks"
     * ativos na aplicacao
     * @method resetAllTimmers
     * @param {*} context 
     * @param {*} value 
     */
    async resetAllTimmers(context, value) {
        await context.commit('clearClockInterval');
        await context.commit('clearClockToConcludedPayment');
        await context.commit('clearTableBlockedInterval');
    },

    async resetClocksAndUnblock(context) {

        context.dispatch('resetAllTimmers');
        await Payments.blockUnblockTablePayment(false);
    },


    /**
     * Recupera dados de configurações para o Google Pay do Backend         
     * @method fetchGooglePayData
     * @param {*} context 
     * @param {*} value 
     */
    async setGooglePayConfigs(context) {
        /** reset anterior */
        context.commit("setGooglePayConfigs", {});
        
        return await axiosInstance({
            method: 'GET',
            url: 'payments/google-pay-data'
        })
        .then(response => response.data.data)
        .then(data => {
            context.commit("setGooglePayConfigs", data);
            return data;
        })
    }, 

    /**
     * Recupera dados de configurações para o Google Pay do Backend         
     * @method setGooglePayOrder
     * @param {*} context 
     * @param {*} value 
     */
    setGooglePayOrder(context, value) {
        context.commit('setGooglePayOrder', value);
    },    
    
    /**
     * Define se o Btn do Google Pay devera ser mostrado ou nao
     * @method setGoogleBtnEnable
     * @param {*} context 
     * @param {*} value 
     */
    setGoogleBtnEnable(context, value) {
        context.commit('setGoogleBtnEnable', value);
    },    
    

    /**
     * Recupera dados de configurações para o Apple Pay do Backend        
     * @method fetchApplePayData
     * @param {*} context 
     * @param {*} value 
     */
    async setApplePayConfigs(context) {
        /** reset anterior */
        context.commit("setApplePayConfigs", {});
        
        return await axiosInstance({
            method: 'GET',
            url: 'payments/apple-pay-data'
        })
        .then(response => response.data.data)
        .then(data => {
            context.commit("setApplePayConfigs", data);
            return data;
        })
    }, 

    /**
     * Recupera dados de configurações para o Google Pay do Backend         
     * @method setGooglePayOrder
     * @param {*} context 
     * @param {*} value 
     */
    setApplePayOrder(context, value) {
        context.commit('setApplePayOrder', value);
    },  
    
    /**
     * Define se o Btn do Apple Pay devera ser mostrado ou nao
     * @method setAppleBtnEnable
     * @param {*} context 
     * @param {*} value 
     */
    setAppleBtnEnable(context, value) {
        context.commit('setAppleBtnEnable', value);
    },       
    setInvoicingWithNif(context, value) {
        context.commit('setInvoicingWithNif', value);
    }
};