import {
    onBuildMenu,
} from '@/services/RouteMiddlewares';

import store from '@/store';

let componentMenu;

const routes = [{
    path: '/menu',
    name: 'menu-route',
    component: () => import( /* webpackChunkName: "menu" */ '@/views/Menu'),
    children: [
        {
            path: '/',
            name: 'menu',
            beforeEnter: async (to, from, next) => {            
                onBuildMenu(to, from, next);
                if (store.getters['Site/storeConfig']?.unit?.active_layout_menu_version_2) {
                    componentMenu = import( /* webpackChunkName: "menu" */ '@/views/MenuV2/MenuList') 
                }
                else if (store.getters['Site/storeConfig']?.unit?.active_layout_menu_version_3) {
                    componentMenu = import( /* webpackChunkName: "menu" */ '@/views/MenuV3/MenuList') 
                } 
                else {
                    componentMenu = import( /* webpackChunkName: "menu" */ '@/views/Menu/MenuList')
                }
            },
            component: () => componentMenu
        },
        {
            path: 'product/:productId?/:familyId?/',
            name: 'menu-product',
            component: () => import( /* webpackChunkName: "product" */ '@/views/Menu/MenuProduct'),
            // children: children, 
        },
    ]
},
]

export default routes;