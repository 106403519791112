import store from "@/store";
import Payments from "./Payments";
import Store from "./Store";

export default class SiteInit {
    /**
     * método que sempre é invocado quando a página da F5 ou carrega 
     * a primeira vez
     */
    static start() {
        //geolocation from user          
        let storeClass = new Store();
        storeClass.handleGeolocationNeed();
        storeClass.loadLanguageFromSession();
        storeClass.handleSelectLanguageNeed();

        /* verifica se existe pagamentos em aberto */
        let paymentClass = new Payments();
        paymentClass.checkPaymentOpened();
    }
}