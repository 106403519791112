<template>
  <b-modal id="modal-terms-drink" centered class="modal-terms-drink" no-close-on-backdrop>
    <div class="modal-terms-drink-itens">
      <div class="modal-terms-drink-itens-body">
        <div class="modal-drink--header">
          <h2>{{ $t('modal_drink.title') }}</h2>
        </div>
        <div class="modal-drink--text">
          <p class="text-center">{{ $t('modal_drink.content') }}</p>
        </div>
        <div class="modal-drink-btn">
          <button class="btn-white" @click="$bvModal.hide('modal-terms-drink')" v-if="false">
            {{ $t('buttons.choose_another_drink') }}
          </button>
          <button class="btn-black" @click="$bvModal.hide('modal-terms-drink')">
            OK
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  data() {
    return {
    }
  },
}
</script>

<style lang="scss">
#modal-terms-drink {
	.modal-header, .modal-footer {
		display: none;
	}
	.modal-content {
		border-radius: 10px;
		box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
		border: 0;
    @media (max-width: 991px){
      height: 100%;
      box-shadow: none;
    }
	}
	.modal-body {
		padding: 0;
	}
}
@import './index.scss';
</style>
