export default {
  setMenuItems(state, value) {
    state.menuItems = value;
    state.menuItemKey = (new Date).getTime();
  },
  setLoading(state, value){
    state.loading = value;
  },
  setProduct(state, value){
    state.product = value;
  },
  setToogleActive(state, {varName, varValue}){
    state[varName] = varValue;
  },
  setMenuBreadcrumb(state, value){
    state.breadcrumb = value;
  },
};