import Vue from 'vue'
import VueI18n from 'vue-i18n';
import store from '@/store'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)  
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new VueI18n({
  //adicionei validação para caso não tenha store, para podermos usar em arquivos .js
  locale: (store?.getters['Site/locale'] ?? 'pt'),
	fallbackLocale: (store?.getters['Site/locale'] ?? 'pt'),
  messages: loadLocaleMessages()
})