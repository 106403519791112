// import { axiosInstance, axiosWithoutLoading, AxiosError } from "@/libs/axios";
import store from '@/store';
export default {
    setLoading(context, value) {
        context.commit('setLoading', value);
    },
    async setIsPrePayment(context) {
        context.commit('setIsPrePayment', await context.dispatch('isStorePrePayment'))
    },
    
    /**
     * Verifica se a loja possui configuracoes para 
     * prepgamento ativas.     
     */
    isStorePrePayment() {

        //config apenas para a amostra dia 12
        let serial = store.getters['Site/storeSerial'];              
        let storeConfig = store.getters['Site/storeConfig'];

        // a loja nao foi identificada
        if (!storeConfig) return false;

        // a mesa em prepagamento não pode ser zero.
        if (serial.tableNumber == 0) return false;

        const hasMesaPagamento = storeConfig.mesaPagamento == 1;

        return hasMesaPagamento;
    }
};