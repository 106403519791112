import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import filters from './filters';

import './plugins'

Vue.config.productionTip = false
Vue.config.devtools = process.env.VUE_APP_ENV == 'development'
Vue.use(require('vue-moment'));
Vue.use(filters);

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.$app = app;