import store from "@/store";
import { randomNumber } from "@/utils";
import axios from "axios";

/**
 * AxiosInstance contém loading no painel   
 */
 export const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL_API,    
});

export const axiosWithoutLoading = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL_API,
    // withCredentials: false,
});

const handleError = (error) => {
    let dataError = {        
        message: ('message' in error.response ? error.response.message : error.response.statusText)
    };

    if('data' in error.response && error.response.data){
        dataError = 'data' in error.response.data ? error.response.data.data : error.response.data;
    }
    
    dataError.type = 'error';
    
    store.dispatch('Modal/setAlert', dataError, {
        root: true
    });
    
    store.dispatch('Site/loading', false, {
        root: false
    });
    // whatever you want to do with the error
    throw error;
}

axiosWithoutLoading.interceptors.request.use(function (config) {
    let storeSerial = store.getters['Site/storeSerial'];
    let visitorToken = sessionStorage.getItem('x-biip-visitorToken') || randomNumber();

    if(!sessionStorage.getItem('x-biip-visitorToken')){
        sessionStorage.setItem('x-biip-visitorToken', visitorToken);
    }
    
    config.headers['X-Store-Serial'] = JSON.stringify(storeSerial);
    config.headers['X-Visitor-Token'] = visitorToken;
    config.headers['X-Lang'] = sessionStorage.getItem('x-biip-locale');

    return config;
});

axiosInstance.interceptors.request.use(function (config) {    
    let storeSerial = store.getters['Site/storeSerial'];
    let visitorToken = sessionStorage.getItem('x-biip-visitorToken') || randomNumber();

    if(!sessionStorage.getItem('x-biip-visitorToken')){
        sessionStorage.setItem('x-biip-visitorToken', visitorToken);
    }
    
    config.headers['X-Store-Serial'] = JSON.stringify(storeSerial);
    config.headers['X-Visitor-Token'] = visitorToken;
    config.headers['X-Lang'] = sessionStorage.getItem('x-biip-locale');

    store.dispatch('Site/loading', true, {
        root: true
    });

    return config;
});

axiosInstance.interceptors.response.use((response) => {
    store.dispatch('Site/loading', false, {
        root: true
    });
    
    return response
}, (error) => {
    handleError(error);
});

axiosWithoutLoading.interceptors.response.use((response) => {    
    return response
}, (error) => {
    handleError(error);
});